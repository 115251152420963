import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import("@/views/auth/Login.vue"),
    meta: {
      title: 'OS Helocus - Login'
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import("@/views/dashboard/Index.vue"),
    meta: {
      title: 'OS Helocus - Dashboard'
    }
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/bookings',
    name: 'bookings',
    component: () => import('@/views/bookings/Index.vue'),
    meta: {
      title: 'OS Helocus - Bookings'
    }
  },
  {
    path: '/bookings/create',
    name: 'bookingsCreate',
    component: () => import('@/views/bookings/Create.vue'),
    meta: {
      title: 'OS Helocus - Create Bookings'
    }
  },
  {
    path: '/bookings/detail/:id',
    name: 'bookingsDetail',
    component: () => import('@/views/bookings/Detail.vue'),
    meta: {
      title: 'OS Helocus - Bookings Detail'
    }
  },
  {
    path: '/bookings/detail/:id/checkin',
    name: 'bookingsDetailCheckin',
    component: () => import('@/views/bookings/Checkin.vue'),
    meta: {
      title: 'OS Helocus - Bookings Checkin'
    }
  },
  {
    path: '/history',
    name: 'history',
    component: () => import('@/views/history/Index.vue'),
    meta: {
      title: 'OS Helocus - History'
    }
  },
  {
    path: '/rooms',
    name: 'rooms',
    component: () => import('@/views/rooms/Index.vue'),
    meta: {
      title: 'OS Helocus - Rooms'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
