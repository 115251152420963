<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App" />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  components: {
    HelloWorld
  },
  data() {
    return {
      token: localStorage.getItem('token')
    }
  },

  created() {
    if (this.token) {
      return this.$router.push('dashboard')
    } else {
      return this.$router.push('login')
    }
  }


}
</script>
